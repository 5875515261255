import { useEffect, useState, useRef } from "react"
import { TextField, Button, Select, MenuItem } from "@mui/material"
import { useEthers, useContractFunction, useNotifications } from "@usedapp/core"
import 'reactjs-popup/dist/index.css';
import { styled } from '@mui/material/styles'
import { getContract } from '../helpers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

const TextFieldInput = styled(TextField)(({ theme }) => ({
    '& input[type=number]': {
        'mozAppearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        'webkitAppearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        'webkitAppearance': 'none',
        margin: 0
    }
}));

export const OwnerAction = () => {

    const contract = getContract()
    const { notifications } = useNotifications()

    const [params, setParams] = useState({ date: '', compare_date: '', compare_price_cents: 0, open_timestamp: 0, open_date: '', close_timestamp: 0, close_date: '', settle_after_timestamp: 0, settle_after_date: '' })

    const { state, send } = useContractFunction(contract, 'resetContract', { transactionName: "Reset Contract" })

    const [isResetOpen, setIsResetOpen] = useState(false);

    const [closeHour, setCloseHour] = useState(10);

    function toggleMore() {
        setIsResetOpen(wasOpened => !wasOpened);
    }

    const handleCloseHourChange = (value: number) => {
        setCloseHour(value)
    }

    useEffect(() => {
        async function getParams() {
            // date YYYY-mm-dd
            var date = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('T')[0]
            // compare date YYYY-mm-dd
            var yesterdays_date = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24)
            var compare_date = new Date(yesterdays_date.toString().split('GMT')[0] + ' UTC').toISOString().split('T')[0]
            // compare price cents amt (int)
            var api_response = await fetch("https://api.voltex.ca/aeso/poolPrice?start_date=" + compare_date)
            var data = await api_response.json()
            var compare_price_cents = Math.round(data.flat_price * 100)
            // timestamps (int/epoch)
            var open_timestamp = new Date().setHours(0, 0, 0, 0);
            var close_timestamp = new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * closeHour // last number here is hours after open, so 17 is 5pm
            var settle_after_timestamp = new Date().setHours(0, 0, 0, 0) + 1000 * 60 * 60 * 26

            var open_date = new Date(open_timestamp).toLocaleString();
            var close_date = new Date(close_timestamp).toLocaleString();
            var settle_after_date = new Date(settle_after_timestamp).toLocaleString();

            setParams({
                date: date, compare_date: compare_date, compare_price_cents: compare_price_cents,
                open_timestamp: open_timestamp / 1000, open_date: open_date, close_timestamp: close_timestamp / 1000,
                close_date: close_date, settle_after_timestamp: settle_after_timestamp / 1000,
                settle_after_date: settle_after_date
            })
        }
        getParams()
    }, [closeHour])

    const handleClick = () => {

        const form = resetForm.current

        var date = ''
        var compare_date = ''
        var compare_price_cents = 0
        var open_timestamp = 0
        var close_timestamp = 0
        var settle_timestamp = 0

        if (form) {
            date = form["date"]['value']
            compare_date = form["compare_date"]['value']
            compare_price_cents = form["compare_price_cents"]['value']
            open_timestamp = form["open_timestamp"]['value']
            close_timestamp = form["close_timestamp"]['value']
            settle_timestamp = form["settle_timestamp"]['value']
        }

        try {
            send(
                date,
                compare_date,
                compare_price_cents,
                [open_timestamp, close_timestamp, settle_timestamp],
                {}
            )
            console.log(
                date,
                compare_date,
                compare_price_cents,
                [open_timestamp, close_timestamp, settle_timestamp],
            )
        } catch (error) {
            window.alert(error);
        }

    }

    const [stage, setStage] = useState('');

    useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Reset Contract").length > 0) {
            setStage('Completed')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Reset Contract").length > 0) {
            setStage('Waiting...')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionFailed" &&
                notification.transactionName === "Reset Contract").length > 0) {
            setStage('Failed')
        }
    }, [notifications])

    const resetForm = useRef(null)
    const varForm = useRef(null)


    if (stage == "Waiting...") {
        return (
            <div>
                <p><Button endIcon={isResetOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="outlined" size="small" onClick={toggleMore}>Reset Contract</Button></p>
                {isResetOpen && (
                    <div>
                        <Alert severity="info">Waiting for transaction to complete...<LinearProgress /></Alert>
                    </div>
                )}
            </div>
        )
    } else if (stage == "Completed") {
        return (
            <div>
                <p><Button endIcon={isResetOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="outlined" size="small" onClick={toggleMore}>Reset Contract</Button></p>
                {isResetOpen && (
                    <div>
                        <Alert severity="success">The contract has been reset.</Alert>
                    </div>
                )}
            </div>
        )
    } else if (stage == "Failed") {
        return (
            <div>
                <p><Button endIcon={isResetOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="outlined" size="small" onClick={toggleMore}>Reset Contract</Button></p>
                {isResetOpen && (
                    <div>
                        <Alert severity="error">Reset failed, please try again.</Alert>
                    </div>
                )}
            </div>
        )
    }


    return (
        <div>
            <p><Button endIcon={isResetOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="outlined" size="small" onClick={toggleMore}>Reset Contract</Button></p>
            {isResetOpen && (
                <div className='row'>
                    <div>
                        <strong>Reset Contract</strong>
                    </div>
                    <div>
                        Parameters:
                    </div>
                    <form ref={varForm}>
                        <div>
                            Closing Hour (Local):
                            <div>
                                <Select
                                    style={{ marginRight: '10px' }}
                                    variant="filled"
                                    name="closing_hour_local"
                                    key={`select-${closeHour}`}
                                    defaultValue={closeHour}
                                    // onChange={handleCloseHourChange}
                                    onChange={event => handleCloseHourChange(event.target.value as number)}
                                >
                                    <MenuItem value={1}>01</MenuItem>
                                    <MenuItem value={2}>02</MenuItem>
                                    <MenuItem value={3}>03</MenuItem>
                                    <MenuItem value={4}>04</MenuItem>
                                    <MenuItem value={5}>05</MenuItem>
                                    <MenuItem value={6}>06</MenuItem>
                                    <MenuItem value={7}>07</MenuItem>
                                    <MenuItem value={8}>08</MenuItem>
                                    <MenuItem value={9}>09</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={13}>13</MenuItem>
                                    <MenuItem value={14}>14</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={16}>16</MenuItem>
                                    <MenuItem value={17}>17</MenuItem>
                                    <MenuItem value={18}>18</MenuItem>
                                    <MenuItem value={19}>19</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={21}>21</MenuItem>
                                    <MenuItem value={22}>22</MenuItem>
                                    <MenuItem value={23}>23</MenuItem>
                                    <MenuItem value={24}>24</MenuItem>
                                </Select>
                                {/* <TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`HH`} variant="filled" name="closing_hour_local"
                                    key={`0`} type="number" defaultValue={closeHour} onChange={handleCloseHourChange}
                                    InputProps={{ inputProps: { min: "1", max: "24" } }}
                                /> */}
                            </div>
                        </div>
                    </form>
                    <form ref={resetForm}>
                        <div>
                            Date: {params.date}
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" variant="filled" name="date"
                                key={`1`} type="date" defaultValue={params.date} /></div>
                        </div>
                        <div>
                            Compare Date: {params.compare_date}
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" variant="filled" name="compare_date"
                                key={`2`} type="date" defaultValue={params.compare_date} /></div>
                        </div>
                        <div>
                            Compare Price (in cents): {params.compare_price_cents}
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`Price in cents`} variant="filled" name="compare_price_cents"
                                key={`3`} type="number" defaultValue={params.compare_price_cents} /></div>
                        </div>
                        <div>
                            Open Timestamp: {params.open_date} ({params.open_timestamp})
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`Epoch`} variant="filled" name="open_timestamp"
                                key={`4`} type="number" defaultValue={params.open_timestamp} /></div>
                        </div>
                        <div>
                            Close Timestamp: {params.close_date} ({params.close_timestamp})
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`Epoch`} variant="filled" name="close_timestamp"
                                key={`5`} type="number" value={params.close_timestamp} /></div>
                        </div>
                        <div>
                            Settle Timestamp: {params.settle_after_date} ({params.settle_after_timestamp})
                            <div><TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`Epoch`} variant="filled" name="settle_timestamp"
                                key={params.settle_after_timestamp} type="number" defaultValue={params.settle_after_timestamp} /></div>
                        </div>
                    </form>
                    <div>
                        <Button style={{ marginTop: '3px' }} variant="contained" color="primary" onClick={handleClick}>Reset</Button>
                    </div>
                </div>
            )}
        </div>
    )

}