import PriceCompareContract from "./chain-info/contracts/PriceCompareContract.json"
import ConfigHelper from "./helper-config.json"
import ContractMap from "./chain-info/deployments/map.json"
import { ethers, providers } from "ethers"
import { Kovan, Polygon, Mumbai, Mainnet } from '@usedapp/core';
import ConfigBrownie from './brownie-config.json'


export function getContract() {
    const activeNetworkName = ConfigHelper['active_network']
    const activeNetworkDetails = ConfigHelper['networks'][activeNetworkName]
    const network = ethers.providers.getNetwork(activeNetworkName)
    const apiKey = activeNetworkDetails.api_key

    let tempProvider;

    if (activeNetworkDetails.provider == "Etherscan") {
        tempProvider = new providers.EtherscanProvider(network, apiKey)
    } else if (activeNetworkDetails.provider == "Infura") {
        tempProvider = new ethers.providers.InfuraProvider(network, apiKey)
    }

    const provider = tempProvider;
    const simpleContractInterface = new ethers.utils.Interface(PriceCompareContract["abi"]);
    const contractAddress = ContractMap[network.chainId]["PriceCompareContract"][0]
    const priceCompareContract = new ethers.Contract(contractAddress, simpleContractInterface, provider)

    return priceCompareContract;
}

export function getTokenName() {
    const activeNetworkName = ConfigHelper['active_network']
    const network = ConfigHelper['networks'][activeNetworkName]
    const token_name = network['token_name']
    return token_name
}

export function getFeePercent() {
    const activeNetworkName = ConfigHelper['active_network']
    const network = ConfigHelper['networks'][activeNetworkName]
    const fee_percent = network['fee_percent']
    return fee_percent
}

export function getContractExplorerLink() {
    const activeNetworkName = ConfigHelper['active_network']
    const network = ethers.providers.getNetwork(activeNetworkName)
    const explorer_url = ConfigHelper['networks'][activeNetworkName]['explorer_base_url']
    const contractAddress = ContractMap[network.chainId]["PriceCompareContract"][0]
    const contractURL = explorer_url + contractAddress
    return contractURL
}

export function getMetamaskNetwork() {
    const activeNetworkName = ConfigHelper['active_network']

    if (activeNetworkName == 'maticmum') {
        return [Mumbai]
    }

    if (activeNetworkName == 'kovan') {
        return [Kovan]
    }

    if (activeNetworkName == 'matic') {
        return [Polygon]
    }

    if (activeNetworkName == 'mainnet') {
        return [Mainnet]
    }

}

export function getNetworkName() {
    const activeNetworkName = ConfigHelper['active_network']

    if (activeNetworkName == 'maticmum') {
        return "Matic Mumbai (Test)"
    }

    if (activeNetworkName == 'kovan') {
        return "Kovan (Test)"
    }

    if (activeNetworkName == 'matic') {
        return "Polygon/Matic Mainnet"
    }

    if (activeNetworkName == 'mainnet') {
        return "Ethereum Mainnet"
    }
}

export function isTestNetwork() {
    let isTest = false
    const activeNetwork = getMetamaskNetwork()

    if (activeNetwork && activeNetwork[0]) {
        if (activeNetwork[0].isTestChain) {
            isTest = true
        }
    }
    return isTest
}

export function getExplicitTokenName() {
    let token = getTokenName()
    const activeNetwork = getMetamaskNetwork()

    if (isTestNetwork()) {
        token = 'TEST ' + token
    }
    return token
}

export function getFundingAmounts() {
    var obj = { "max_funds_per_side": 0, "max_funder_funds": 0, "min_funder_funds": 0 }
    const activeNetworkName = ConfigHelper['active_network']
    const brownieName = ConfigHelper['networks'][activeNetworkName]['brownie-name']
    obj.max_funds_per_side = (ConfigBrownie['networks'][brownieName].max_funds_per_side) / 10 ** 18
    obj.max_funder_funds = (ConfigBrownie['networks'][brownieName].max_funder_funds) / 10 ** 18
    obj.min_funder_funds = (ConfigBrownie['networks'][brownieName].min_funder_funds) / 10 ** 18

    return obj
}

export function getMultiplier() {
    const activeNetworkName = ConfigHelper['active_network']

    var multiplier = 0

    if (activeNetworkName == 'kovan') {
        var multiplier = 1000
    }

    if (activeNetworkName == 'mainnet') {
        var multiplier = 1000
    }

    if (activeNetworkName == 'matic') {
        var multiplier = 10
    }

    if (activeNetworkName == 'maticmum') {
        var multiplier = 10
    }

    return multiplier
}

export function getOwnerAccount() {
    const owner = ConfigHelper['owner_account']

    return owner
}