import { useEthers, useEtherBalance } from "@usedapp/core"
import { PriceHeader } from "./PriceHeader";
import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { styled } from '@mui/material/styles'
import { ProgressBar } from "./ProgressBar"
import { AccountProgressBar } from "./AccountProgressBar"
import { Bid } from "./Bid"
import { Footer } from "./Footer"
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import { getContract, getTokenName, getOwnerAccount } from '../helpers'
import { OwnerAction } from "./OwnerAction";
import { SettleContract } from "./SettleContract"
import Paper from '@mui/material/Paper';
import { stringify } from "querystring";
import { Pool } from "@mui/icons-material";

const PredictUpIcon = styled(KeyboardDoubleArrowUpIcon)(({ theme }) => ({
    fontSize: 'inherit !important',
}));

const PredictDownIcon = styled(KeyboardDoubleArrowDownIcon)(({ theme }) => ({
    fontSize: 'inherit !important',
}));

const PredictButton = styled(Button)(({ theme }) => ({
    width: '80%',
    fontWeight: 'bold',
    fontSize: 'xx-large !important'
}));

const ContainerDiv = styled('div')(({ theme }) => ({
    textAlign: 'center',
}));

export const Main = () => {

    const { account } = useEthers()

    const priceCompareContract = getContract();

    const [active, setActive] = React.useState(1);
    const SetView = (active: any) => {
        setActive(active);
    };

    const ActiveView = () => {
        switch (active) {
            case 1:
                return <Bid predict="higher" />;
            case 2:
                return <Bid predict="lower" />;
            default:
                return <></>;
        }
    }

    const [type, setType] = useState('')

    function padLeadingZeros(num: any, size: any) {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    const owner_account = getOwnerAccount()

    const [accountContribution, setAccountContribution] = useState({ address: '', accountHigherContribution: 0, accountLowerContribution: 0 })
    const [contractBalance, setContractBalance] = useState({ totalHigherBalance: 0, totalLowerBalance: 0 })
    const [contractState, setContractState] = useState({ contractStatus: 0, contractCloseTimestamp: 0, contractOpenTimestamp: 0, contractSettleAfterTimestamp: 0, contractDate: '', contractCompareDate: '' })
    const [openState, setOpenState] = useState({ remaining: "", seconds: 0, isOpen: false, isPending: true, contractAddress: priceCompareContract.address })
    const [currentTimestamp, setCurrentTimestamp] = useState({ currentTimestamp: Math.round(new Date().getTime() / 1000), tenSecondCounter: 0, sixtySecondCounter: 0 })
    const [currentPoolPrice, setCurrentPoolPrice] = useState(0)

    useEffect(() => {
        async function fetchCurrentPoolPrice() {
            if (contractState.contractDate !== "") {
                let date = contractState.contractDate
                let api_response = await fetch("https://api.voltex.ca/aeso/poolPrice?start_date=" + date + "&prelim_ok")
                let data = await api_response.json()
                let PoolPrice: number = data.flat_price.toFixed(2)

                setCurrentPoolPrice(PoolPrice)
            }
        }
        fetchCurrentPoolPrice()
    }, [contractState])

    useEffect(() => {
        async function fetchAccountContributions(account: any) {
            let contractTotalLowerBalance = await priceCompareContract.lowerBalances(account)
            let contractTotalHigherBalance = await priceCompareContract.higherBalances(account)

            setAccountContribution({ address: String(account), accountHigherContribution: Number(contractTotalHigherBalance) / 10 ** 18, accountLowerContribution: Number(contractTotalLowerBalance) / 10 ** 18 })
        }
        if (account) {
            fetchAccountContributions(account)
        }

    }, [contractBalance, account])

    useEffect(() => {
        async function fetchContractBalance() {

            let contractTotalHigherBalance = await priceCompareContract.totalHigherBalance()
            let contractTotalLowerBalance = await priceCompareContract.totalLowerBalance()

            if ((Number(contractTotalHigherBalance) / 10 ** 18 != contractBalance.totalHigherBalance) || (Number(contractTotalLowerBalance) / 10 ** 18 != contractBalance.totalLowerBalance)) {
                setContractBalance({ totalHigherBalance: Number(contractTotalHigherBalance) / 10 ** 18, totalLowerBalance: Number(contractTotalLowerBalance) / 10 ** 18 })
            }
        }
        fetchContractBalance()
    }, [currentTimestamp.tenSecondCounter])

    useEffect(() => {
        async function fetchContractDetails() {
            let contractStatus = await priceCompareContract.contract_state()
            let contractCloseTimestamp = await priceCompareContract.timestamps(1)
            let contractOpenTimestamp = await priceCompareContract.timestamps(0)
            let contractSettleAfterTimestamp = await priceCompareContract.timestamps(2)
            let contractDate = await priceCompareContract.date()
            let contractCompareDate = await priceCompareContract.compareDate()

            setContractState({ contractStatus: Number(contractStatus), contractCloseTimestamp: Number(contractCloseTimestamp), contractOpenTimestamp: Number(contractOpenTimestamp), contractSettleAfterTimestamp: Number(contractSettleAfterTimestamp), contractDate: contractDate, contractCompareDate: contractCompareDate })
        }
        fetchContractDetails()
    }, [currentTimestamp.sixtySecondCounter])


    useEffect(() => {
        async function updateCountdown() {
            if (contractState.contractCloseTimestamp) {
                const totalSeconds = (contractState.contractCloseTimestamp - currentTimestamp.currentTimestamp)
                let hours = Math.floor(totalSeconds / 3600); // get hours
                let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60); // get minutes
                let seconds = totalSeconds - (hours * 3600) - (minutes * 60); //  get seconds
                const timeRemaining = new String(hours + ':' + padLeadingZeros(minutes, 2) + ':' + padLeadingZeros(seconds, 2));
                let isContractOpen = false
                if ((currentTimestamp.currentTimestamp > contractState.contractOpenTimestamp && currentTimestamp.currentTimestamp < contractState.contractCloseTimestamp) && (contractState.contractStatus == 1)) {
                    isContractOpen = true
                }
                setOpenState({
                    ...openState,
                    remaining: timeRemaining.toString(),
                    seconds: totalSeconds,
                })

                if (isContractOpen != openState.isOpen) {
                    setOpenState({
                        ...openState,
                        isOpen: isContractOpen,
                        isPending: false
                    })
                } else if (openState.isPending) {
                    setOpenState({
                        ...openState,
                        isPending: false
                    })
                }

            }
        }
        updateCountdown()
    }, [currentTimestamp])

    const counter = React.useRef(1);

    useEffect(() => {
        async function updateTimestamp() {
            setCurrentTimestamp(prevState => ({ ...prevState, currentTimestamp: Math.round(new Date().getTime() / 1000) }))
            if (counter.current % 10 === 0) {
                setCurrentTimestamp(prevState => ({ ...prevState, tenSecondCounter: prevState.tenSecondCounter + 1 }))
            }
            if (counter.current % 60 === 0) {
                setCurrentTimestamp(prevState => ({ ...prevState, sixtySecondCounter: prevState.sixtySecondCounter + 1 }))
            }
            counter.current += 1
        }
        updateTimestamp()
        setInterval(() => { updateTimestamp() }, 1 * 1000)
    }, [])

    var settleContract = <></>

    if (
        (
            accountContribution.accountHigherContribution > 0 ||
            accountContribution.accountLowerContribution > 0 ||
            account == owner_account
        ) &&
        (
            contractBalance.totalHigherBalance > 0 ||
            contractBalance.totalLowerBalance > 0
        ) &&
        (
            contractState.contractStatus != 6
        ) &&
        (Date.now() / 1000 > contractState.contractSettleAfterTimestamp)
    ) {
        var settleContract = <SettleContract />
    }

    var ownerActions = <></>

    if (
        account == owner_account &&
        (
            (
                contractBalance.totalLowerBalance == 0 &&
                contractBalance.totalHigherBalance == 0
            )
            ||
            contractState.contractStatus == 6
        )
    ) {
        var ownerActions = <OwnerAction />
    }

    let footer_props = {
        openState: openState,
        contractState: contractState
    }

    const [comparePriceState, setComparePriceState] = useState({ comparePrice: 0 });

    useEffect(() => {
        async function fetchContractDetails() {
            if (!openState.isPending) {
                let contractComparePrice = await priceCompareContract.comparePriceCentsAmount()

                setComparePriceState({ comparePrice: Number(contractComparePrice) / 100 })
            }
        }
        fetchContractDetails()
    }, [openState.isOpen, openState.isPending])

    const handleClickHigher = () => {
        SetView(1)
        setType('higher')
    }

    const handleClickLower = () => {
        SetView(2)
        setType('lower')
    }

    function refreshPage() {
        setType('')
    }

    if (openState.isPending) {
        return <ContainerDiv>
            <div style={{ paddingBottom: "30px", paddingTop: "30px" }}>
                <Alert severity="info">Loading contract...<LinearProgress /></Alert>
            </div>

        </ContainerDiv >
    }
    else if (type == 'higher' || type == 'lower') {
        return <ContainerDiv>
            <PriceHeader  {...comparePriceState} />
            <h5>Today's price is ${currentPoolPrice}/MWh so far.</h5>
            <div>
                <h3>I predict today will be:</h3>
            </div>
            <Paper sx={{ padding: '15px 0 15px 0' }}>
                <PredictButton color="primary" disabled={true} variant="contained" startIcon={type == 'higher' ? <PredictUpIcon /> : <PredictDownIcon />} endIcon={type == 'higher' ? <PredictUpIcon /> : <PredictDownIcon />} onClick={() => SetView(1)}>{type}</PredictButton>
                <div style={{ marginTop: '10px' }}>
                    <Bid predict={type} />
                </div>
                <div>
                    <p>
                        <Button variant="contained" onClick={refreshPage}>Return to home</Button>
                    </p>
                </div>
                <div>
                    <div>
                        <ProgressBar key="higher" predict="higher" completed={contractBalance.totalHigherBalance} />
                        {account && (
                            <AccountProgressBar key="accountHigher" predict="higher" completed={accountContribution.accountHigherContribution} />
                        )}
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <ProgressBar key="lower" predict="lower" completed={contractBalance.totalLowerBalance} />
                        {account && (
                            <AccountProgressBar key="accountLower" predict="lower" completed={accountContribution.accountLowerContribution} />
                        )}
                    </div>
                </div>
            </Paper>

            <div>
                <Footer {...footer_props} />
            </div>
        </ContainerDiv>;

    } else if (type == 'lower') {
        return <ContainerDiv>
            <PriceHeader  {...comparePriceState} />
            <h5>Today's price is ${currentPoolPrice}/MWh so far.</h5>
            <div>
                <div>
                    <h3>I predict today will be:</h3>
                </div>
                <PredictButton disabled={true} variant="contained" startIcon={<PredictDownIcon />} endIcon={<PredictDownIcon />} onClick={() => SetView(2)}>Lower</PredictButton>
            </div>
            <div>
                <Bid predict="lower" />
            </div>
            <div>
                <p>
                    <Button variant="contained" onClick={refreshPage}>Return to home</Button>
                </p>
            </div>
            <div>
                <div>
                    <ProgressBar key="higher" predict="higher" completed={contractBalance.totalHigherBalance} />
                    {account && (
                        <AccountProgressBar key="accountHigher" predict="higher" completed={accountContribution.accountHigherContribution} />
                    )}
                </div>
                <div style={{ marginTop: '15px' }}>
                    <ProgressBar key="lower" predict="lower" completed={contractBalance.totalLowerBalance} />
                    {account && (
                        <AccountProgressBar key="accountLower" predict="lower" completed={accountContribution.accountLowerContribution} />
                    )}
                </div>
            </div>
            <div>
                <Footer {...footer_props} />
            </div>
        </ContainerDiv>;
    } else {
        return <ContainerDiv>
            <PriceHeader  {...comparePriceState} />
            <h5>Today's price is ${currentPoolPrice}/MWh so far.</h5>

            <div><h3>Will today be:</h3></div>
            <Paper sx={{ padding: '15px 0 15px 0' }}>
                <div>
                    <PredictButton disabled={!openState.isOpen} variant="contained" startIcon={<PredictUpIcon />} endIcon={<PredictUpIcon />} onClick={handleClickHigher}>Higher</PredictButton>
                    <ProgressBar key="higher" predict="higher" completed={contractBalance.totalHigherBalance} />
                    {account && (
                        <AccountProgressBar key="accountHigher" predict="higher" completed={accountContribution.accountHigherContribution} />
                    )}

                </div>


                <div>
                    <h2>- OR -</h2>
                </div>
                <div>
                    <PredictButton disabled={!openState.isOpen} variant="contained" startIcon={<PredictDownIcon />} endIcon={<PredictDownIcon />} onClick={handleClickLower}>Lower</PredictButton>
                    <ProgressBar key="lower" predict="lower" completed={contractBalance.totalLowerBalance} />
                    {account && (
                        <AccountProgressBar key="accountLower" predict="lower" completed={accountContribution.accountLowerContribution} />
                    )}
                </div>
            </Paper>
            <div>
                <Footer {...footer_props} />
            </div>
            <div className="row">
                <div className="column">
                    <div>{ownerActions}</div>
                </div>
                <div className="column">
                    <div>{settleContract}</div>
                </div>
            </div>
        </ContainerDiv>;
    }
}