import React from "react";
import { getTokenName, getMultiplier } from '../helpers'

export const AccountProgressBar = (props: any) => {
  const { completed, predict } = props;

  const tokenName = getTokenName();

  const multiplier = getMultiplier()

  const containerStyles = {
    height: 22,
    width: '80%',
    margin: 'auto',
    backgroundColor: "rgba(90, 94, 90, 0.2)",
    marginTop: 5,
    borderRadius: 15,
    overflow: 'hidden'

  }

  const fillerStyles = {
    height: '100%',
    transform: `translateX(-${100 - (completed * multiplier)}%)`,
    width: '100%',
    backgroundColor: 'rgba(90, 94, 90, 0.3)',
    borderRadius: 'inherit',
  }

  const labelStyles = {
    padding: 0,
    color: 'rgba(90, 94, 90, 0.7)',
    fontWeight: 'bold',
    float: "left",
    width: '100%'
  } as const


  return (
    <div style={containerStyles}>
      <span style={labelStyles}>YOU BET {`${completed} ${tokenName} ON ${predict.toUpperCase()}`}</span>
      <div style={fillerStyles}></div>
    </div>
  );
};