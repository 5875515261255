import { Container } from "@mui/material";
import Chip from '@mui/material/Chip';
import { Info } from "./Info"
import Alert from '@mui/material/Alert';





export const Footer = (footer_props: any) => {

    var close_timestamp = (footer_props.contractState.contractCloseTimestamp) * 1000

    var close = new Date(close_timestamp)

    var monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    var close_month = monthNames[close.getMonth()]

    var close_day = close.getDate()

    var ordinal = "th";

    var j = close_day % 10
    var k = close_day % 100
    if (j == 1 && k != 11) {
        var ordinal = "st";
    }
    if (j == 2 && k != 12) {
        var ordinal = "nd";
    }
    if (j == 3 && k != 13) {
        var ordinal = "rd";
    }

    var close_hours: number = close.getHours()

    if (close_hours < 12) {
        var period = 'AM'
    } else if (close_hours == 12) {
        var period = 'PM'
    } else {
        var close_hours = close_hours - 12
        var period = 'PM'
    }

    var close_minutes: any = close.getMinutes()
    if (close_minutes == 0) {
        close_minutes = new String('00')
    }

    var contract_end: any = close_hours + ':' + close_minutes + ' ' + period + ' on ' + close_month + ' ' + close_day + ordinal

    if (footer_props.openState.isOpen) {
        return (
            <Container style={{
                padding: 1,
                textAlign: "center",
                justifyContent: "between",
                gap: 1,
                margin: 'auto',
            }}>
                <div>
                    <h4>You have <Chip color="warning" style={{ fontSize: "inherit", paddingBottom: "5px", paddingTop: "5px" }} label={footer_props.openState.remaining} /> to decide.</h4>
                </div>
                <div>
                    <p>The contract will close at: {contract_end}</p>
                </div>
                <Info contractAddress={footer_props.openState.contractAddress} />
            </Container>
        )
    } else {
        return (
            <Container style={{
                padding: 1,
                textAlign: "center",
                justifyContent: "between",
                gap: 1,
                margin: 'auto',
            }}>
                <div>
                    <Alert severity="warning" sx={{ marginTop: '10px' }}>The contract is closed and will re-open after settlement.</Alert>
                </div>
                <Info contractAddress={footer_props.openState.contractAddress} />
            </Container>
        )
    }

}