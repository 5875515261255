import { useState } from "react"
import Button from "@mui/material/Button"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { getContractExplorerLink, isTestNetwork, getNetworkName, getExplicitTokenName, getFundingAmounts, getFeePercent } from '../helpers'




export const Info: React.FC<{ contractAddress: "" }> = props => {

    const [isMoreOpened, setMoreIsOpened] = useState(false);

    const contractUrl = getContractExplorerLink()

    const tokenName = getExplicitTokenName()
    const onTestNetwork = isTestNetwork()

    let activeNetworkName = getNetworkName()

    const fundingAmounts = getFundingAmounts()
    const feePercent = getFeePercent()

    function toggleMore() {
        setMoreIsOpened(wasOpened => !wasOpened);
    }

    const [isEvenMoreOpened, setEvenMoreIsOpened] = useState(false);

    function toggleEvenMore() {
        setEvenMoreIsOpened(wasOpened => !wasOpened);
    }

    return (
        <div>
            <p><Button endIcon={isMoreOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="outlined" size="small" onClick={toggleMore}>more info</Button></p>
            {isMoreOpened && (
                <div>
                    <div style={{ textAlign: 'left' }}>
                        <p>
                            The max total bet for each side of the contract is <strong>{fundingAmounts.max_funds_per_side} {tokenName}</strong>. The max amount you can send to the contract is <strong>{fundingAmounts.max_funder_funds} {tokenName}</strong>.
                            Whichever side wins will split the funds that were sent to the losing side on a pro-rata basis.
                            {tokenName == 'MATIC' && (
                                <span> <strong>MATIC</strong> is the native cryptocurrency of the Polygon network. It can be bought and sold on many exchanges. Sometimes the currency is called Polygon, but the symbol for it is typically just: <strong>MATIC</strong>.</span>
                            )}
                        </p>
                    </div>
                    <div>
                        <p><Button endIcon={isEvenMoreOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} color="primary" variant="text" size="small" onClick={toggleEvenMore}>tell me more</Button></p>
                    </div>
                </div>
            )}

            {isEvenMoreOpened && isMoreOpened && (
                <div style={{ textAlign: 'left' }}>
                    <p>
                        This web app interacts with a smart contract that lives on the {activeNetworkName} network.
                    </p>
                    <p>
                        You can view the contract (including the source code) and all transactions <Link href={contractUrl} target="_blank" rel="noopener">here<LaunchIcon style={{ fontSize: 'small' }} /></Link>.
                    </p>
                    {onTestNetwork && (
                        <p>
                            Because this contract is on a test network, the funds it receives and distributes have no real value.
                        </p>
                    )}

                    <p>
                        In order to participate in the contract, you need to:
                    </p>
                    <ol>
                        <li>Have <Link href="https://metamask.io" target="_blank" rel="noopener">MetaMask<LaunchIcon style={{ fontSize: 'small' }} /></Link> installed as an extension in your browser.</li>
                        <li>Set it to use the {activeNetworkName} network (instructions <Link href="https://docs.polygon.technology/docs/develop/metamask/config-polygon-on-metamask/" target="_blank" rel="noopener">here<LaunchIcon style={{ fontSize: 'small' }} /></Link>).</li>
                        <li>Fund your MetaMask wallet with {tokenName}.</li>
                    </ol>

                    <p>
                        Once you've completed those steps, you'll be able to place a bet on this page. If you chose correctly, you will receive a portion of the funds submitted by those who chose incorrectly.
                    </p>
                    <p>
                        Funds are distributed based on how much you bet. For example, if your bet made up 25% of all winning bets, you would receive your {tokenName} back, plus 25% of the {tokenName} bet on the losing side.
                        To help cover the costs associated with developing and running the game a {feePercent}% fee is deducted from losing side before it is split and sent to the winners.
                    </p>
                    <p>
                        This project is meant to be an interactive educational tool. It was created by <Link href="https://www.voltex.ca" target="_blank" rel="noopener">Voltex Solutions Inc.<LaunchIcon style={{ fontSize: 'small' }} /></Link>
                    </p>
                </div>
            )}
        </div>
    )


}