import React, { useEffect, useState, Component } from "react"
import { useEthers, useEtherBalance, useContractCall } from "@usedapp/core"
import { ethers, utils, providers } from "ethers"
import PriceCompareContract from "../chain-info/contracts/PriceCompareContract.json"
import { SendEthToContractHigher } from "./SendEthToContractHigher"
import { SendEthToContractLower } from "./SendEthToContractLower"
import { ConnectToMetamask } from "./ConnectToMetamask"
import { render } from "react-dom"
import { Container } from "@mui/material"

export const Bid = (props: any) => {
    const { predict } = props;
    var send = <></>
    if (predict == "higher")
        send = <SendEthToContractHigher />
    else if (predict == "lower")
        send = <SendEthToContractLower />

    return (
        <Container style={{
            padding: 1,
            display: "flex-row",
            justifyContent: "center",
            gap: 1,
            marginBottom: '20px'
        }}>
            <div>
                {send}
            </div>
        </Container>
    )

}