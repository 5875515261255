import Button from "@mui/material/Button"
import { useContractFunction, useNotifications } from "@usedapp/core"
import { getContract } from '../helpers'
import { useState, useEffect } from "react"
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

export const SettleContract = () => {

    const contract = getContract()
    const { notifications } = useNotifications()

    const { state, send } = useContractFunction(contract, 'settle', { transactionName: "Settle Contract" })

    const handleClick = () => {

        try {
            send()
        } catch (error) {
            window.alert(error);
        }

    }

    const [stage, setStage] = useState('');

    useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Settle Contract").length > 0) {
            setStage('Completed')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Settle Contract").length > 0) {
            setStage('Waiting...')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionFailed" &&
                notification.transactionName === "Settle Contract").length > 0) {
            setStage('Failed')
        }
    }, [notifications])

    if (stage == "Waiting...") {
        return (
            <div>
                <Alert severity="info">Waiting for transaction to complete...<LinearProgress /></Alert>
            </div>
        )
    } else if (stage == "Completed") {
        return (
            <div>
                <Alert severity="success">The contract has been settled.</Alert>
            </div>
        )
    } else if (stage == "Failed") {
        return (
            <div>
                <Alert severity="error">Settlement failed, please try again.</Alert>
            </div>
        )
    }


    return (
        <div>
            <p>If you participated in this contract, you can force it to settle yourself:</p>
            <Button style={{ marginTop: '3px', marginBottom: '20px' }} variant="contained" color="primary" onClick={handleClick}>Settle Contract</Button>
        </div>
    )
}