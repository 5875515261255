import { useEthers } from "@usedapp/core"
import Button from '@mui/material/Button';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles'
import { isMobile } from 'react-device-detect';
import { useState } from "react"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { getNetworkName } from '../helpers'

const ConnectedCircle = styled(CircleIcon)(({ theme }) => ({
    fontSize: 'inherit !important',
}));

export const WebThreeConnector = () => {
    const networkName = getNetworkName()

    const { account, activateBrowserWallet, deactivate } = useEthers()

    const isConnected = account !== undefined

    const accountString = String(account).substring(0, 6) + '...' + String(account).substring(String(account).length - 4)

    function deactivateMetaMask() {
        localStorage.setItem('disconnect', '1')
        setIsDetailsOpen(false)
        deactivate()
    }

    async function connectMetaMask() {
        localStorage.setItem('disconnect', '0')
        try {
            await activateBrowserWallet(undefined, true)
        } catch (error) {
            window.alert('Make sure your Metamask Wallet is connected to the ' + networkName + ' network.');
        }
    }

    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    function toggleConnectionDetails() {
        setIsDetailsOpen(wasOpened => !wasOpened);
    }

    var customButton = <></>
    var connectionDetails = <></>

    if (isConnected) {
        connectionDetails =
            <div>
                <Button startIcon={isConnected ? <ConnectedCircle style={{ fill: "#4caf50", marginBottom: "3px" }} /> : <ConnectedCircle color="error" />} endIcon={isDetailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} variant="text" onClick={toggleConnectionDetails} style={{ color: 'white', fontSize: 'small' }}>{accountString}</Button>
            </div>
    } else if (!isMobile) {
        customButton = <Button style={{ fontSize: 'x-small', marginBottom: '10px' }} variant="contained" color="warning" onClick={connectMetaMask}>
            Connect to Web3
        </Button>
        connectionDetails =
            <div>
                {customButton}
            </div>
    }

    return (
        <div>
            <div style={{ float: 'right' }}>
                {connectionDetails}
                {isDetailsOpen && (
                    <div style={{ float: 'right', fontSize: 'small', textAlign: 'right' }}>
                        <Button style={{ fontSize: 'x-small' }} color="warning" variant="contained" onClick={deactivateMetaMask}>Disconnect</Button>
                    </div>
                )}
                {!isDetailsOpen && !isMobile && (
                    <div style={{ marginTop: '-8px', float: 'right', fontSize: 'small', textAlign: 'right' }}>
                        {networkName}
                    </div>
                )}
            </div>
            <div style={{ clear: 'both' }}></div>
        </div>
    )

}