import { DAppProvider, Kovan, Polygon, Mumbai, ChainId } from '@usedapp/core';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import { Container } from "@mui/material"
import { Main } from "./components/Main"
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getMetamaskNetwork } from "./helpers"
import CssBaseline from '@mui/material/CssBaseline';

let autoConnect = true

if (localStorage.getItem('disconnect') == '1') {
  autoConnect = false
  console.log('disconnect was reqested')
}

const theme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fcfcfc'
    }
  }
})

const activeNetworkName = getMetamaskNetwork()

function App() {
  return (
    <DAppProvider config={{
      networks: activeNetworkName,
      autoConnect: autoConnect,
      notifications: {
        expirationPeriod: 1000,
        checkInterval: 1000
      }
    }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <ResponsiveAppBar />

        <Container maxWidth="sm">
          <Main />
        </Container>
      </ThemeProvider>

    </DAppProvider>
  );
}

export default App;
