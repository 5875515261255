import { useEffect, useState } from "react"
import { TextField, Button } from "@mui/material"
import { useEthers, useNotifications, useContractFunction } from "@usedapp/core"
import { utils } from "ethers"
import 'reactjs-popup/dist/index.css';
import { styled } from '@mui/material/styles'
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import { getContract, getTokenName, getNetworkName, getFundingAmounts } from '../helpers'



const TextFieldInput = styled(TextField)(({ theme }) => ({
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
}));

export const SendEthToContractLower = () => {
    const [amount, setAmount] = useState<number | string | Array<number> | string>(0)
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = event.target.value === "" ? "" : Number(event.target.value)
        setAmount(newAmount)
        console.log(newAmount)
    }
    const { account, activateBrowserWallet } = useEthers()
    const { notifications } = useNotifications()

    const contract = getContract()

    const { state, send } = useContractFunction(contract, 'predictLower', { transactionName: "Predict Lower" })

    const [data, setData] = useState('');

    const [stage, setStage] = useState('');

    const [error, setError] = useState('');

    const [exception, setException] = useState(false)

    const tokenName = getTokenName();

    const networkName = getNetworkName()

    const fundingAmounts = getFundingAmounts()

    const handleClick = () => {

        if (amount > fundingAmounts.max_funder_funds) {
            setError('min')
        } else if (amount < fundingAmounts.min_funder_funds) {
            setError('max')
        } else {
            const string_amount = String(amount)

            send({ value: utils.parseEther(string_amount) })

            setData('Sent ' + string_amount + " " + tokenName + " to contract (lower)")
        }

    }

    async function connectMetaMask() {
        localStorage.setItem('disconnect', '0')
        try {
            await activateBrowserWallet(undefined, true)
        } catch (error) {
            window.alert('Make sure your Metamask Wallet is connected to the ' + networkName + ' network.');
        }
    }

    var errorMessage = <></>

    if (error == 'min') {
        var errorMessage = <div><p><Alert severity="warning">The maximum amount you can bet is {fundingAmounts.max_funder_funds} {tokenName}.</Alert></p></div>
    } else if (error == 'max') {
        var errorMessage = <div><p><Alert severity="warning">The minimum amount you can bet is {fundingAmounts.min_funder_funds} {tokenName}.</Alert></p></div>
    }

    var exceptionMessage = <></>

    if (exception == true) {
        var exceptionMessage = <div><p><Alert severity="error">There was an error. Would sending this exceed the {fundingAmounts.max_funder_funds} {tokenName} limit?</Alert></p></div>
    }

    useEffect(() => {
        if (state.status == 'Exception') {
            setException(true)
        }
    }, [state])

    useEffect(() => {
        if (notifications.filter(
            (notification) =>
                notification.type === "transactionSucceed" &&
                notification.transactionName === "Predict Lower").length > 0) {
            setStage('Completed')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionStarted" &&
                notification.transactionName === "Predict Lower").length > 0) {
            setStage('Waiting...')
        }
        else if (notifications.filter(
            (notification) =>
                notification.type === "transactionFailed" &&
                notification.transactionName === "Predict Lower").length > 0) {
            setStage('Failed')
        }
    }, [notifications])

    if (account) {
        if (stage == "Waiting...") {
            return (
                <div>
                    <Alert severity="info">Waiting for transaction to complete...<LinearProgress /></Alert>
                </div>
            )
        } else if (stage == "Completed") {
            return (
                <div>
                    <Alert severity="success">Your <strong>{amount}</strong> {tokenName} bet has been placed!</Alert>
                </div>
            )
        } else if (stage == "Failed") {
            return (
                <div>
                    <Alert severity="error">Transaction failed, please try again.</Alert>
                </div>
            )
        }
        return (
            <>
                <div>
                    <TextFieldInput autoFocus style={{ marginRight: '10px' }} size="small" label={`Amount (${fundingAmounts.min_funder_funds} to ${fundingAmounts.max_funder_funds} ${tokenName})`} variant="filled"
                        onChange={handleInputChange} type="number" />
                    <Button style={{ marginTop: '3px' }} size="large" variant="contained" color="primary" onClick={handleClick}>Bet (Lower)</Button>
                </div>
                <div>
                    {exceptionMessage}
                    {errorMessage}
                </div>
            </>
        )
    } else {
        return (
            <div>
                <p>
                    <Button variant="contained"
                        color="primary"
                        onClick={connectMetaMask}
                    >
                        Connect to Metamask To Place a Bet
                    </Button>
                    <br />
                    <small>You must be on desktop or using the MetaMask app on your phone.</small>
                </p>

                <p><Link href="https://metamask.io/" target="_blank" rel="noopener">Learn about MetaMask <LaunchIcon style={{ fontSize: 'small' }} /></Link></p>
            </div>
        )
    }

}
