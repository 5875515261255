import { ethers, providers } from "ethers"
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

export const PriceHeader = (comparePriceState: any) => {

    const price = comparePriceState.comparePrice.toFixed(2)

    return (
        <div style={{ justifyContent: 'space-between', textAlign: "center" }}>
            <h3 >
                <Tooltip enterTouchDelay={0} leaveTouchDelay={2500} title={<span style={{ fontSize: 'larger' }}>AESO Daily Average (Flat) Price</span>}><span style={{ textUnderlinePosition: "under", textDecoration: "underline", textDecorationStyle: "dotted" }}>Pool Price</span></Tooltip> was <Chip color="secondary" style={{ fontSize: "inherit", paddingBottom: "5px", paddingTop: "5px" }} label={"$" + price} />/MWh yesterday
            </h3>
        </div >
    )
}